import React, { useState } from 'react'
import { API_URL } from '../../environment';
import axios from "axios";
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });
export const RtoNtoFiletoDbtoVir = () => {
    const [note, setNote] = useState("");
    const [loading, setLoading] = React.useState(false);
    const [query, setQuery] = React.useState('idle');
    const timerRef = React.useRef();
    const [file, setFile] = useState(null);
    const handleTextareaChange = (e) => {
        setNote(e.target.value);
    }
    React.useEffect(
        () => () => {
          clearTimeout(timerRef.current);
        },
        [],
    );
    const handleClickLoading = () => {
        setLoading((prevLoading) => !prevLoading);
    };
    
    
    const handleFileChange = async (e) => {
        setFile(e.target.files[0]);
    };
    const handleUpload = async (e) => {
        handleClickLoading();
        e.preventDefault();
        const formData = new FormData();
        formData.append('file', file);
        formData.append('note', note);
        
        await axios.post(`${API_URL}/api/save_temp_detailstodbtovirscan`, formData, {
            headers: {
            'Content-Type': 'multipart/form-data',
            },
        })
        .then(response => {
            if(response.status === 200){
                if(response.data.success){
                    handleClickLoading();
                    alert("success");
                }
            }
        })
        .catch(err => console.log(err))
        
    };
    return (
        <div>
            <div>
            <Box sx={{ height: 40 }}>
                <Fade
                in={loading}
                style={{
                    transitionDelay: loading ? '800ms' : '0ms',
                }}
                unmountOnExit
                >
                <CircularProgress />
                </Fade>
            </Box>
            </div>
        <h1>File upload </h1>
        <textarea name="note" onChange={handleTextareaChange}></textarea>
        {/* <input type="file" accept=".pdf" onChange={handleFileChange} /> */}
        <Button
        component="label"
        role={undefined}
        variant="contained"
        tabIndex={-1}
        startIcon={<CloudUploadIcon />}
        >
        Upload file
        <VisuallyHiddenInput type="file" accept=".pdf" onChange={handleFileChange} />
        </Button>
        <Button onClick={handleUpload} variant="contained">Submit</Button>
        </div>
    )
}
