// src/components/DataTable.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, Pagination, Button, Spinner, Alert } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom'; // To navigate to the view page
import "./logs.scss";
import { API_URL } from '../../environment';

export const Logs = () => {
  const [data, setData] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortColumn, setSortColumn] = useState('datetime');
  const [sortDirection, setSortDirection] = useState('ASC');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate(); // Hook to navigate

  useEffect(() => {
    fetchData();
  }, [currentPage, sortColumn, sortDirection]);

  const fetchData = async () => {
    setLoading(true);
    setError('');
    try {
      const response = await axios.get(`${API_URL}/api/logs`, {
        params: {
          pageno: currentPage,
          rows_per_page: 10,
          sort_column: sortColumn,
          sort_direction: sortDirection,
        },
      });
      setData(response.data.actions);
      setTotalRecords(response.data.totalRecords);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.error('Error fetching data:', error);
      setError('Failed to fetch logs. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleSort = (column) => {
    setSortDirection(sortDirection === 'ASC' ? 'DESC' : 'ASC');
    setSortColumn(column);
  };

  const handleView = (row) => {
    // Navigate to the view page with the log details
    navigate(`/log/${row.logid}`, { state: row });
  };

  return (
    <div className="table-container">
      <h2>Action Logs</h2>
      {error && <Alert variant="danger">{error}</Alert>}
      {loading ? (
        <div className="text-center">
          <Spinner animation="border" />
        </div>
      ) : (
        <>
          <Table striped bordered hover responsive className="custom-table">
            <thead>
              <tr>
                <th onClick={() => handleSort('logid')}>Log ID</th>
                <th onClick={() => handleSort('ipaddress')}>IP Address</th>
                <th onClick={() => handleSort('userid')}>User ID</th>
                <th onClick={() => handleSort('fileid')}>File ID</th>
                <th onClick={() => handleSort('pageno')}>Page Number</th>
                <th onClick={() => handleSort('message')}>Message</th>
                <th onClick={() => handleSort('old_content')}>Old Content</th>
                <th onClick={() => handleSort('new_content')}>New Content</th>
                <th onClick={() => handleSort('datetime')}>Date Time</th>
                <th>Actions</th> {/* Column for the View button */}
              </tr>
            </thead>
            <tbody>
              {data.length > 0 ? (
                data.map((row) => (
                  <tr key={row.logid}>
                    <td>{row.logid}</td>
                    <td>{row.ipaddress}</td>
                    <td>{row.userid}</td>
                    <td>{row.fileid}</td>
                    <td>{row.pageno}</td>
                    <td>{row.message}</td>
                    <td>{row.old_content}</td>
                    <td>{row.new_content}</td>
                    <td>{row.datetime}</td>
                    <td>
                      <Button variant="primary" onClick={() => handleView(row)}>
                        View
                      </Button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="10" className="text-center">
                    No logs available.
                  </td>
                </tr>
              )}
            </tbody>
          </Table>

          <Pagination>
            {[...Array(totalPages).keys()].map((page) => (
              <Pagination.Item
                key={page + 1}
                active={page + 1 === currentPage}
                onClick={() => handlePageChange(page + 1)}
              >
                {page + 1}
              </Pagination.Item>
            ))}
          </Pagination>
        </>
      )}
    </div>
  );
};
