import React, { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import { API_URL } from '../../environment';
import { TextField, Button, Box, Typography, IconButton } from '@mui/material';
import { Edit, Delete } from '@mui/icons-material';

export const MainCategory = () => {
  const [categories, setCategories] = useState([]);
  const [categoryName, setCategoryName] = useState('');
  const [pageSize, setPageSize] = useState(10);
  const [editingCategory, setEditingCategory] = useState(null); // State to track the category being edited
  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    try {
      const response = await axios.get(`${API_URL}/api/categories`);
      console.log(response)
      const indexedData = response.data[0].map((item, idx) => ({
        ...item,
        index: idx + 1 // Adding an 'index' field starting from 1
      }));
      setCategories(indexedData);
    } catch (error) {
      console.error('Failed to fetch categories:', error);
    }
  };
  const handleAddCategory = async () => {
    if (!categoryName.trim()) {
      alert('Category name is required');
      return;
    }

    try {
      await axios.post(`${API_URL}/api/category`, { categoryName });
      setCategoryName(''); // Clear the input field
      fetchCategories(); // Refresh the list after adding
    } catch (error) {
      console.error('Failed to add category:', error);
    }
  };
  const handleEditCategory = async () => {
    if (!editingCategory.newCatName.trim()) {
      alert('Category name is required');
      return;
    }

    try {
      await axios.put(`${API_URL}/api/category/${editingCategory.CategoryId}`, { newCatName: editingCategory.newCatName });
      setEditingCategory(null); // Clear editing state
      fetchCategories(); // Refresh the list after editing
    } catch (error) {
      console.error('Failed to update category:', error);
    }
  };

  const handleDeleteCategory = async (id) => {
    if (window.confirm('Are you sure you want to delete this category?')) {
      try {
        await axios.delete(`${API_URL}/api/category/${id}`);
        fetchCategories(); // Refresh the list after deletion
      } catch (error) {
        console.error('Failed to delete category:', error);
      }
    }
  };

  const startEditing = (category) => {
    setEditingCategory({ ...category, newCatName: category.LabelText });
  };

  const cancelEditing = () => {
    setEditingCategory(null);
  };

  const columns = [
    { field: 'index', headerName: 'Index', width: 90 },
    { field: 'CategoryId', headerName: 'Cat ID', width: 90 },
    { field: 'CategoryDescription', headerName: 'Description', width: 150 },
    { field: 'LabelText', headerName: 'Label', width: 150 },
    { field: 'CategoryType', headerName: 'Type', width: 150 },
    { field: 'IsActive', headerName: 'Active', width: 110 },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 150,
      renderCell: (params) => (
        <>
          <IconButton onClick={() => startEditing(params.row)}>
            <Edit />
          </IconButton>
          <IconButton onClick={() => handleDeleteCategory(params.row.CategoryId)}>
            <Delete />
          </IconButton>
        </>
      )
    }
    // Add more columns as needed
  ];
  console.log(categories)

  return (
    <div style={{ height: 600, width: '75%', margin:"auto" }}>
      <Box sx={{ mb: 4 }}>
        <Typography variant="h6" gutterBottom>
          {editingCategory ? 'Edit Category' : 'Add New Category'}
        </Typography>
        <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
          <TextField
            label="Category Name"
            value={editingCategory ? editingCategory.newCatName : categoryName}
            onChange={(e) => editingCategory ? setEditingCategory({ ...editingCategory, newCatName: e.target.value }) : setCategoryName(e.target.value)}
            variant="outlined"
            size="small"
          />
          <Button
            variant="contained"
            color="primary"
            onClick={editingCategory ? handleEditCategory : handleAddCategory}
          >
            {editingCategory ? 'Update Category' : 'Add Category'}
          </Button>
          {editingCategory && (
            <Button
              variant="outlined"
              onClick={cancelEditing}
            >
              Cancel
            </Button>
          )}
        </Box>
      </Box>

      <DataGrid
        rows={categories}
        columns={columns}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        pagination
        rowsPerPageOptions={[5, 10, 20]}
        checkboxSelection
        disableSelectionOnClick
        sortingOrder={['desc', 'asc']}
        getRowId={(row) => row.CategoryId}
      />
    </div>
  );
};
