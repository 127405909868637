import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { API_URL } from '../../environment';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import TextField from '@mui/material/TextField';
import TablePagination from '@mui/material/TablePagination';
import TableSortLabel from '@mui/material/TableSortLabel';
import PreviewIcon from '@mui/icons-material/Preview';
import DeleteIcon from '@mui/icons-material/Delete';
import Snackbar, { SnackbarCloseReason } from '@mui/material/Snackbar';

export const AdminList = () => {
  const [files, setFiles] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('id');
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (
    event,
    reason,
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };
  useEffect(() => {
    fetchFileDetails();
  }, []);
  useEffect(() => {
    filterUsers();
  }, [search, files]);
  const fetchFileDetails = async () => {
    try {
      const response = await axios.get(`${API_URL}/api/listoffiles`);
      if(response.data.success){
        setFiles(response.data.files[0][0]);
      }
    } catch (err) {
      console.error('Failed to fetch users', err);
    }
  };

  const deleteFile = async (id) => {
    try {
      await axios.delete(`${API_URL}/api/listoffiles/delete/${id}`);
      handleClick();
      setFiles(files.filter(file => file.SystemGeneratedFileName !== id));
    } catch (err) {
      console.error('Failed to delete user', err);
    }
  };
  const filterUsers = () => {
    const filtered = files.filter(user => 
      user.SystemGeneratedFileName.toLowerCase().includes(search.toLowerCase())
    );
    setFilteredUsers(filtered);
  };

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  const sortedUsers = filteredUsers.sort((a, b) => {
    if (orderBy === 'SystemGeneratedFileName') {
      return (order === 'asc' ? 1 : -1) * a.SystemGeneratedFileName.localeCompare(b.SystemGeneratedFileName);
    } else {
      return (order === 'asc' ? 1 : -1) * (a.FileId - b.FileId);
    }
  });

  return (
    <div>
      <Snackbar
        open={open}
        autoHideDuration={5000}
        onClose={handleClose}
        message="The File deleted successfully."
      />
      <TextField 
        label="Search System Generated Filename" 
        variant="outlined" 
        fullWidth 
        margin="normal" 
        value={search}
        onChange={handleSearchChange}
      />
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
              <TableSortLabel
                  active={orderBy === 'FileId'}
                  direction={orderBy === 'FileId' ? order : 'asc'}
                  onClick={() => handleRequestSort('FileId')}
                >
                  File ID
                </TableSortLabel>
              </TableCell>
              <TableCell>
              <TableSortLabel
                  active={orderBy === 'SystemGeneratedFileName'}
                  direction={orderBy === 'SystemGeneratedFileName' ? order : 'asc'}
                  onClick={() => handleRequestSort('SystemGeneratedFileName')}
                >
                  SystemGeneratedFileName
                </TableSortLabel>
              </TableCell>
              <TableCell>Pages</TableCell>
              <TableCell>Size</TableCell>
              <TableCell>Upload time</TableCell>
              <TableCell>Virus Scan</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          {filteredUsers.length ? (
            <>
            {sortedUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((file) => (
            <TableRow key={file.FileId}>
              <TableCell>{file.FileId}</TableCell>
              <TableCell>{file.SystemGeneratedFileName}</TableCell>
              <TableCell>{file.Pages}</TableCell>
              <TableCell>{file.TextSize} bytes</TableCell>
              <TableCell>{file.FirstUploadTime}</TableCell>
              <TableCell>{file.VirusStatus === "Clean" ? (<><CheckCircleIcon style={{color:"green"}} /></>):(<></>)}</TableCell>
              <TableCell align="right">
              <Button 
                  variant="contained" 
                  color="primary" 
                  style={{marginRight:5}}
                >
                  <PreviewIcon />
                </Button>
                <Button 
                  variant="contained" 
                  color="secondary" 
                  onClick={() => deleteFile(file.SystemGeneratedFileName)}
                >
                  <DeleteIcon />
                </Button>
              </TableCell>
            </TableRow>
          ))}
            </>
            ):(<></>)}
            
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={filteredUsers.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
    
  )
}
