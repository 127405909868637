import React, { useState } from 'react';
import "./searchenhanced.scss";
import { Theme, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { API_URL } from '../../environment';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import PDF from "../../assets/pdf.png";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const names = [
  "History", "Sports"
];
function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}
export const SearchEnhanced = () => {
  const theme = useTheme();
  const [searchresult, setSearchresult] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [year, setYear] = useState("");
  const [categoryName, setCategoryName] = React.useState([]);
  const [yearvalue, setYearValue] = React.useState('match');
  const [author, setAuthor] = useState("");
  const [authorvalue, setAuthorValue] = React.useState('match');
  const [title, setTitle] = useState("");
  const [titlevalue, setTitleValue] = React.useState('match');
  const [keyword, SetKeyword] = useState("");
  const [keywordvalue, SetKeywordValue] = useState("match");
  const [viewselection, SetViewSelection] = useState("standard_view");
  const onchangeYear = (e) => {
    setYear(e.target.value);
  }
  const onchangeAuthor = (e) => {
    setAuthor(e.target.value);
  }
  const onChangeTitle = (e) => {
    setTitle(e.target.value);
  }
  const onChangeKeyword = (e) => {
    SetKeyword(e.target.value);
  }
  const handleYearChange = (event) => {
    setYearValue(event.target.value);
  };
  const handleAuthorChange = (event) => {
    setAuthorValue(event.target.value);
  };
  const handleTitleChange = (event) => {
    setTitleValue(event.target.value);
  };
  const handleCategoryChange = (event) => {
    const {
      target: { value },
    } = event;
    setCategoryName(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };
  const handleKeywordChange = (event) => {
    SetKeywordValue(event.target.value)
  }
  const handleViewSelection = (event) => {
    SetViewSelection(event.target.value)
  }
  const getthesearchdetails = async () => {
    setLoading(true);
    var alldata = {
      year, 
      categoryName,
      yearvalue,
      author,
      authorvalue,
      title,
      titlevalue,
      keyword,
      keywordvalue,
      viewselection
    }
    const rawresponse = await fetch(`${API_URL}/api/getsearchdetails`, {
      method:"POST",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(alldata)
    })
    const content = await rawresponse.json();
    if(content.success){
      setSearchresult(content.results);
      setLoading(false);
    }
  }
  
  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;
  return (
    <div className='mainsearchoption'>
        <h3>Search Option</h3>
        <div className='formdata'>
          <div className='inputdata'>
            <Box
              component="form"
              sx={{
                '& > :not(style)': { m: 1, width: '25ch' },
              }}
              noValidate
              autoComplete="off"
            >
              <TextField id="standard-basic" label="Year" variant="standard" onChange={onchangeYear} /> 
              <FormControl     className='parentselectionfilter'>
              <FormLabel id="demo-row-radio-buttons-group-label">Filter</FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={yearvalue}
                onChange={handleYearChange}
              >
                <FormControlLabel value="match" control={<Radio />} disabled={!year} name="keywordYear" label="Any of the word" className='selectionfilter' />
                <FormControlLabel value="match_phrase" control={<Radio />} disabled={!year} name="keywordYear" label="Exact Match" className='selectionfilter' />
                <FormControlLabel value="match_phrase_prefix" control={<Radio />} disabled={!year} name="keywordYear" label="Starts with" className='selectionfilter' />
              </RadioGroup>
            </FormControl>
              {/* <TextField id="standard-basic" label="Author" variant="standard" />
              <TextField id="standard-basic" label="Title" variant="standard" /> */}
            </Box>
            <Box
              component="form"
              sx={{
                '& > :not(style)': { m: 1, width: '25ch' },
              }}
              noValidate
              autoComplete="off"
            >
              <TextField id="standard-basic" label="Author" variant="standard" onChange={onchangeAuthor} />
              <FormControl     className='parentselectionfilter'>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={authorvalue}
                  onChange={handleAuthorChange}
                >
                  <FormControlLabel value="match" control={<Radio />} disabled={!author} name="keywordAuthor" label="Any of the word" className='selectionfilter' />
                  <FormControlLabel value="match_phrase" control={<Radio />} disabled={!author} name="keywordAuthor" label="Exact Match" className='selectionfilter' />
                  <FormControlLabel value="match_phrase_prefix" control={<Radio />} disabled={!author} name="keywordAuthor" label="Starts with" className='selectionfilter' />
                </RadioGroup>
              </FormControl>
              {/* <TextField id="standard-basic" label="Author" variant="standard" />
              <TextField id="standard-basic" label="Title" variant="standard" /> */}
            </Box>
            <Box
              component="form"
              sx={{
                '& > :not(style)': { m: 1, width: '25ch' },
              }}
              noValidate
              autoComplete="off"
            >
              <TextField id="standard-basic" label="Title" variant="standard" onChange={onChangeTitle} />
              <FormControl     className='parentselectionfilter'>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={titlevalue}
                onChange={handleTitleChange}
              >
                <FormControlLabel value="match" control={<Radio />} disabled={!title} name="keywordTitle" label="Any of the word" className='selectionfilter' />
                <FormControlLabel value="match_phrase" control={<Radio />} disabled={!title} name="keywordTitle" label="Exact Match" className='selectionfilter' />
                <FormControlLabel value="match_phrase_prefix" control={<Radio />} disabled={!title} name="keywordTitle" label="Starts with" className='selectionfilter' />
              </RadioGroup>
            </FormControl>
            </Box>
            <Box
              component="form"
              sx={{
                '& > :not(style)': { m: 1, width: '25ch' },
              }}
              noValidate
              autoComplete="off"
            >
              
              <FormControl sx={{ m: 1, width: 300 }}className='parentselectionactionfilter'>
                <InputLabel id="demo-multiple-name-label">Category</InputLabel>
                <Select
                  labelId="demo-multiple-name-label"
                  id="demo-multiple-name"
                  multiple
                  value={categoryName}
                  onChange={handleCategoryChange}
                  input={<OutlinedInput label="Name" />}
                  MenuProps={MenuProps}
                >
                  {names.map((name) => (
                    <MenuItem
                      key={name}
                      value={name}
                      style={getStyles(name, categoryName, theme)}
                    >
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <div className='MuiFormControl-root parentselectionfilter css-1nrlq1o-MuiFormControl-roots'></div>
            </Box>
            <Box
              component="form"
              sx={{
                '& > :not(style)': { m: 1, width: '25ch' },
              }}
              noValidate
              autoComplete="off"
            >
              <TextField id="standard-basic" label="Keyword" variant="standard" onChange={onChangeKeyword} />
              <div className='MuiFormControl-root parentselectionfilter css-1nrlq1o-MuiFormControl-root'></div>
            </Box>
            <Box
              component="form"
              sx={{
                '& > :not(style)': { m: 1, width: '25ch' },
              }}
              noValidate
              autoComplete="off"
            >
              <label style={{textAlign:"left"}}>Search method</label>
              <FormControl     className='parentselectionfilter'>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={keywordvalue}
                  onChange={handleKeywordChange}
                >
                  <FormControlLabel value="match" control={<Radio />} disabled={!keyword} name="keywordvalue" label="Any of the word" className='selectionfilter' />
                  <FormControlLabel value="match_phrase" control={<Radio />} disabled={!keyword} name="keywordvalue" label="Exact Match" className='selectionfilter' />
                  <FormControlLabel value="match_phrase_prefix" control={<Radio />} disabled={!keyword} name="keywordvalue" label="Starts with" className='selectionfilter' />
                </RadioGroup>
              </FormControl>
            </Box>
            <Box
              component="form"
              sx={{
                '& > :not(style)': { m: 1, width: '25ch' },
              }}
              noValidate
              autoComplete="off"
            >
              <label style={{textAlign:"left"}}>Please select your view</label>
              <FormControl     className='parentselectionfilter'>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={viewselection}
                  onChange={handleViewSelection}
                >
                  <FormControlLabel value="standard_view" control={<Radio />} disabled={!keyword} name="standard_view" label="Standard View" className='selectionfilter' />
                  <FormControlLabel value="detailed_view" control={<Radio />} disabled={!keyword} name="detailed_view" label="Detailed View" className='selectionfilter' />
                </RadioGroup>
              </FormControl>
            </Box>
            <Stack spacing={2} direction="row">
              <Button variant="contained" onClick={ () => getthesearchdetails()}>Search</Button>
            </Stack>
          </div>
        </div>
        <div className="search-results">
          <h3>Search result</h3>
      {searchresult.map((result,index) => (
        <div key={index+1} className="result-card">
          <h3 className="filename"><a href={result.systemgeneratedfilename}><img src={`${PDF}`} alt="PDF Icon" className="pdf-icon" width={30} /></a></h3>
          <p className="page-number">{result.systemgeneratedfilename}, Page Number: {result.pagenumber.replace(/^0+/, '')}</p>
          <p className="content" dangerouslySetInnerHTML={{ __html: result.highlightedContent }} />
        </div>
      ))}
    </div>
       
    </div>
  )
}
