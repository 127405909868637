import React from 'react';
import { styled } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { AdminSearch } from '../AdminSearch/AdminSearch';
import { AdminList } from '../AdminList/AdminList';
import { SearchEnhanced } from '../SearchEnhanced/SearchEnhanced';
import { SearchBasic } from '../SearchBasic/SearchBasic';
import { RtoNtoFiletoDbtoVir } from '../RtoNtoFiletoDBtoVir/RtoNtoFiletoDbtoVir';
import { MainCategory } from '../MainCategory/MainCategory';
const AntTabs = styled(Tabs)({
    borderBottom: '1px solid #e8e8e8',
    '& .MuiTabs-indicator': {
      backgroundColor: '#1890ff',
    },
  });
  const AntTab = styled((props) => <Tab disableRipple {...props} />)(
    ({ theme }) => ({
      textTransform: 'none',
      minWidth: 0,
      [theme.breakpoints.up('sm')]: {
        minWidth: 0,
      },
      fontWeight: theme.typography.fontWeightRegular,
      marginRight: theme.spacing(1),
      color: 'rgba(0, 0, 0, 0.85)',
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      '&:hover': {
        color: '#40a9ff',
        opacity: 1,
      },
      '&.Mui-selected': {
        color: '#1890ff',
        fontWeight: theme.typography.fontWeightMedium,
      },
      '&.Mui-focusVisible': {
        backgroundColor: '#d1eaff',
      },
    }),
  );
export const MainPage = () => {
    const [value, setValue] = React.useState(0);
  const renderSwitch = (param) => {
      switch(param) {
          case 0:
            return <RtoNtoFiletoDbtoVir />;
          case 1:
            return <SearchBasic />;
          case 2:
            return <SearchEnhanced />;
          case 3:
            return <AdminList />;
          case 4:
            return <AdminSearch />;
          case 5:
            return <MainCategory />;
          default:
            return <></>;
        }
  }
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div className="App">
      <h1>POC for text content ingest into ES </h1>
      <Box sx={{ width: '100%', border:"1px solid #ccc" }}>
        <Box sx={{ bgcolor: '#fff' }}>
          <AntTabs value={value} onChange={handleChange} aria-label="ant example">
          <AntTab label="File upload" />
          <AntTab label="Search Basic" />
          <AntTab label="Search Enhanced" />
          <AntTab label="Admin List" />
          <AntTab label="Admin Search" />
          <AntTab label="Admin Category Page" />
          </AntTabs>
          <Box sx={{ p: 3 }} label="listDatapoints">{renderSwitch(value)}</Box>
        </Box>   
      </Box>
      
    </div>
  )
}
