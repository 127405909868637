// src/components/ViewLog.js
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Card, Button } from 'react-bootstrap';
import './viewLog.scss'; // SCSS styling

export const ViewLog = () => {
  const location = useLocation();
  const logData = location.state; // Extract the passed log data
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1); // Navigate to the previous page
  };

  return (
    <div className="view-log-container">
      <h2 className="text-center">Log Details</h2>
      <Card className="log-details-card">
        <Card.Body>
          <Card.Title className="text-center">Log ID: {logData.logid}</Card.Title>
          <Card.Text><strong>IP Address:</strong> {logData.ipaddress}</Card.Text>
          <Card.Text><strong>User ID:</strong> {logData.userid}</Card.Text>
          <Card.Text><strong>File ID:</strong> {logData.fileid}</Card.Text>
          <Card.Text><strong>Page Number:</strong> {logData.pageno}</Card.Text>
          <Card.Text><strong>Message:</strong> {logData.message}</Card.Text>
          <Card.Text><strong>Old Content:</strong> {logData.old_content}</Card.Text>
          <Card.Text><strong>New Content:</strong> {logData.new_content}</Card.Text>
          <Card.Text><strong>Date Time:</strong> {logData.datetime}</Card.Text>
        </Card.Body>
      </Card>
      <div className="back-button-container">
        <Button variant="secondary" onClick={handleBack}>Back</Button>
      </div>
    </div>
  );
};
