import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Document, Page, pdfjs } from 'react-pdf';
import pdfjsWorker from 'pdfjs-dist/legacy/build/pdf.worker.min.mjs'; // Import the correct worker
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
import { API_URL } from '../../../environment';
import { Button, Form } from 'react-bootstrap';
import "./viewdocument.scss";

// Set up the PDF.js worker
// pdfjs.GlobalWorkerOptions.workerSrc = "pdfjs-dist/build/pdf.worker.min.mjs";

const ViewDocument = ({ initialPage = 1 }) => {
  const navigate = useNavigate(); // Updated to useNavigate
  const [numPages, setNumPages] = useState(null);
  const [currentPage, setCurrentPage] = useState(initialPage);
  const [documentContent, setDocumentContent] = useState('');
  const [pdfFileUrl, setPdfFileUrl] = useState('');
  const [targetPage, setTargetPage] = useState(initialPage); // State for targeted page input
  const { pagenumber, systemgeneratedfilename, FileId } = useParams();
  const newpagenumber = pagenumber.replace(/^0+/, '');

  useEffect(() => {
    let isMounted = true;

    // Fetch the document based on systemgeneratedfilename
    fetch(`${API_URL}/api/adminsearch/edit/view/${systemgeneratedfilename}/${pagenumber}/${FileId}`)
      .then(response => response.json())
      .then(data => {
        if (isMounted) {
          setDocumentContent(data.content);
          setCurrentPage(Number(newpagenumber));
          setPdfFileUrl(data.pdfUrl); // URL to your PDF file
        }
      })
      .catch(error => console.error('Error fetching document:', error));

    // Cleanup function to prevent setting state on unmounted component
    return () => {
      isMounted = false;
    };
  }, [pagenumber, systemgeneratedfilename, newpagenumber]);

  // Handle document load success
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  // Navigate to EditDocument page
  const goToEditPage = () => {
    navigate(`/adminsearch/edit/edit/${systemgeneratedfilename}/${pagenumber}/${FileId}`);
  };

  // Handle navigation to targeted page
  const goToTargetPage = () => {
    if (targetPage > 0 && targetPage <= numPages) {
      setCurrentPage(targetPage);
    }
  };
  const handleBack = () => {
    navigate(`/adminsearch/edit/${systemgeneratedfilename}`); // or any other path you want to navigate back to
  };

  return (
    <div className="view-document-container">
      <div className="document-content">
        <h2>Page Number: {newpagenumber}</h2>
        <p>{documentContent}</p>
        <Button variant="primary"  onClick={goToEditPage}>Edit</Button> {/* Edit Button */}
        <Button variant="secondary" onClick={handleBack} style={{marginLeft:10}}>Back</Button>
      </div>

      {/* PDF Viewer */}
      {pdfFileUrl && (
        <div className="pdf-viewer-container">
          <Document
            file={pdfFileUrl}
            onLoadSuccess={onDocumentLoadSuccess}
            className="pdf-document"
          >
            <Page pageNumber={currentPage} width={600} />
          </Document>
          <div className="pagination-controls">
            <Button
              variant="primary"
              disabled={currentPage <= 1}
              onClick={() => setCurrentPage(1)}
            >
              First
            </Button>
            <Button
              disabled={currentPage <= 1}
              onClick={() => setCurrentPage(prevPage => Math.max(prevPage - 1, 1))}
            >
              Previous
            </Button>
            <span>
              Page {currentPage} of {numPages}
            </span>
            <Button
              disabled={currentPage >= numPages}
              onClick={() => setCurrentPage(prevPage => Math.min(prevPage + 1, numPages))}
            >
              Next
            </Button>
            <Button
              disabled={currentPage >= numPages}
              onClick={() => setCurrentPage(numPages)}
            >
              Last
            </Button>
            
            <Form.Control
              type="number"
              min="1"
              max={numPages}
              value={targetPage}
              onChange={e => setTargetPage(Number(e.target.value))}
              style={{ width: '100px' }}
            />
            <Button onClick={goToTargetPage}>
              Go
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ViewDocument;
