import axios from 'axios';
import React, { useState } from 'react';
import styles from "./searchbasic.module.scss";
import { ES_PASS, ES_USERNAME } from '../../environment';

export const SearchBasic = () => {
    const [query, setQuery] = useState('');
    const [results, setResults] = useState([]);
    const [loading, setLoading] = useState(false);
    const handleSearch = async () => {
        setLoading(true);
        try {
          const response = await axios.post(
            'http://localhost:9200/tadatest/_search',
            {
              query: {
                bool: {
                  must: [
                    { match: { content: query } },
                  ]
                }
              },
              highlight: {
                fields: {
                  content: {},
                  page_number: {}
                }
              }
            },
            {
              auth: {
                username: ES_USERNAME,
                password: ES_PASS
              }
            }
          );
          console.log(response)
          setResults(response.data.hits.hits);
        } catch (error) {
          console.error('Error searching Elasticsearch:', error);
        } finally {
          setLoading(false);
        }
    };
    const renderHighlightedText = (sourceText, highlightFragments) => {
        if (!highlightFragments) return sourceText;
    
        const highlightedText = highlightFragments.join(' ... ');
        return <span dangerouslySetInnerHTML={{ __html: highlightedText }} />;
      };
  return (
    <div className={styles.container}>
      <h1 className={styles.header}>Elasticsearch Search</h1>
      <div className={styles.inputgroup}>
        <input
          type="text"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          placeholder="Search..."
        />
        <button onClick={handleSearch}>Search</button>
      </div>
      {loading && <p>Loading...</p>}
      <ul className={styles.results}>
        {results.map((result) => (
          <li key={result._id} className={styles['result-item']}>
            <strong>Page Number:</strong>{result._source.pagenumber.replace(/^0+/, '')}
            <br />
            <strong>Content:</strong>{' '}
            {result.highlight && result.highlight.content
              ? renderHighlightedText(result._source.content, result.highlight.content)
              : result._source.content}
          </li>
        ))}
      </ul>
    </div>
  )
}
