import React, { useEffect, useState } from 'react';
import axios from "axios";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, TablePagination, Typography, Container, Grid, TextField, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Edit, Delete } from '@mui/icons-material';
import { API_URL } from '../../environment';
import { useNavigate } from 'react-router-dom';
export const AdminSearch = () => {
  const [rowsPerPage, setRowsPerPage] = useState(10);
    const [page, setPage] = useState(0);
    const [sortedData, setSortedData] = useState([]);
    const [orderBy, setOrderBy] = useState('year');
    const [orderDirection, setOrderDirection] = useState('asc');
    const [year, setYear] = useState('');
    const [author, setAuthor] = useState('');
    const [title, setTitle] = useState('');
    const [status, setStatus] = useState('');
    const [category, setCategory] = useState('');
    const [results, setResults] = useState([]);
    const navigate = useNavigate();
    useEffect(() => {
        handleSort(orderBy, orderDirection);
    }, [results, orderBy, orderDirection]);

    const handleSort = (field) => {
        const isAsc = orderBy === field && orderDirection === 'asc';
        setOrderDirection(isAsc ? 'desc' : 'asc');
        setOrderBy(field);

        const sorted = [...results].sort((a, b) => {
            if (a[field] < b[field]) return isAsc ? -1 : 1;
            if (a[field] > b[field]) return isAsc ? 1 : -1;
            return 0;
        });

        setSortedData(sorted);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleEdit = (row) => {
      navigate(`/adminsearch/edit/${row.SystemGeneratedFileName}`);
    };

    // const handleDelete = (row) => {
    //     if (window.confirm('Are you sure you want to delete this record?')) {
    //         onDelete(row);
    //     }
    // };
    
    const handleSearch = async (e) => {
        e.preventDefault();
    
        try {
          const response = await axios.post(`${API_URL}/api/searchFiles`, {
            year: parseInt(year) || 0,
            author,
            title,
            status,
            category,
          });
  
          console.log(response)
          
          if(response.status === 200){
            setResults(response.data[0]);
          }
        } catch (error) {
          console.error('Error fetching search results:', error);
        }
    };
    const handleReset = () => {
        setYear('');
        setAuthor('');
        setTitle('');
        setStatus('');
        setCategory('');
        setResults([]);
    };
    
  return (
    <Container maxWidth="xl" style={{margin:"auto"}}>
      <Typography variant="h4" gutterBottom>
        File Details Search
      </Typography>
      <form onSubmit={handleSearch}>
        <Grid container spacing={5} alignItems="center">
          <Grid item>
            <TextField
              label="Year"
              type="number"
              variant="outlined"
              value={year}
              onChange={(e) => setYear(e.target.value)}
              style={{ minWidth: 120 }}
            />
          </Grid>
          <Grid item>
            <TextField
              label="Author"
              variant="outlined"
              value={author}
              onChange={(e) => setAuthor(e.target.value)}
              style={{ minWidth: 120 }}
            />
          </Grid>
          <Grid item>
            <TextField
              label="Title"
              variant="outlined"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              style={{ minWidth: 120 }}
            />
          </Grid>
          <Grid item>
            <FormControl variant="outlined" style={{ minWidth: 120 }}>
              <InputLabel>Category</InputLabel>
              <Select
                value={category}
                onChange={(e) => setCategory(e.target.value)}
                label="Category"
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value="history">History</MenuItem>
                
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl variant="outlined" style={{ minWidth: 120 }}>
              <InputLabel>Status</InputLabel>
              <Select
                value={status}
                onChange={(e) => setStatus(e.target.value)}
                label="Status"
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value="processed">Processed</MenuItem>
                <MenuItem value="Admin Review">Unprocessed</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <Button type="submit" variant="contained" color="primary">
              Search
            </Button>
          </Grid>
          <Grid item>
            <Button type="button" variant="outlined" color="secondary" onClick={handleReset}>
              Reset
            </Button>
          </Grid>
        </Grid>
      </form>

      {results.length > 0 ? (
        <Paper>
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell onClick={() => handleSort('year')}>Year</TableCell>
                        <TableCell onClick={() => handleSort('author')}>Author</TableCell>
                        <TableCell onClick={() => handleSort('author')}>systemgeneratedfilename</TableCell>
                        <TableCell onClick={() => handleSort('title')}>Title</TableCell>
                        <TableCell onClick={() => handleSort('category')}>Category</TableCell>
                        <TableCell onClick={() => handleSort('status')}>Status</TableCell>
                        <TableCell>Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {sortedData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                        <TableRow key={row.FileId}>
                            <TableCell>{row.Year}</TableCell>
                            <TableCell>{row.Author}</TableCell>
                            <TableCell>{row.SystemGeneratedFileName}</TableCell>
                            <TableCell>{row.Title}</TableCell>
                            <TableCell>{row.category}</TableCell>
                            <TableCell>{row.NextStep}</TableCell>
                            <TableCell>
                                <Button onClick={() => handleEdit(row)} startIcon={<Edit />}>Edit</Button>
                                <Button onClick={() => {}} startIcon={<Delete />} color="secondary">Delete</Button>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
        <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={results.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
        />
    </Paper>
      ) : (
        <Typography variant="h6" style={{ marginTop: '20px' }}>
          No results found
        </Typography>
      )}
    </Container>
  )
}
