import React from 'react';
import './CustomDialog.scss'; // Import your SCSS file for styling

const CustomDialog = ({ open, onClose, onConfirm }) => {
  if (!open) return null;

  return (
    <div className="custom-dialog-overlay">
      <div className="custom-dialog">
        <h2>Unsaved Changes</h2>
        <p>You have unsaved changes. Do you want to discard them?</p>
        <button onClick={onConfirm}>Discard</button>
        <button onClick={onClose}>Cancel</button>
      </div>
    </div>
  );
};

export default CustomDialog;
