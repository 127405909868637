import React, { useEffect, useState } from 'react';
import { Radio, RadioGroup, FormControlLabel, FormControl, TextField, Button, Paper, Snackbar, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination } from '@mui/material';
import "./AdminSearchEdit.scss";
import { useNavigate, useParams } from 'react-router-dom';
import { API_URL } from '../../../environment';
import axios from 'axios';
import CustomDialog from './CustomDialog';
import CloseIcon from '@mui/icons-material/Close';
export const AdminSearchEdit = ({ match }) => {
    const { systemgeneratedfilename } = useParams(); // Get the ID from the URL
    const navigate = useNavigate(); // useNavigate replaces useHistory in React Router v6

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [documents, setDocuments] = useState([]);
    const [totalResults, setTotalResults] = useState(0);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [error, setError] = useState("");
    const [initialData, setInitialData] = useState({
        Title: '',
        Author: '',
        Year: '',
        SystemGeneratedFileName: '',
        OriginalFileName:'',
        Notes:'',
        Message:'Pdf File successfully converted to multiple text files.Please check output folder.',
        Pages:'',
        TextSize:'',
        PdfSize:'',
        VirusStatus:'',
        CurrentStatus:'',
        FilePath:''
    });
    const [isFieldChanged, setIsFieldChanged] = useState({
        Title: false,
        Author: false,
        Year: false,
        SystemGeneratedFileName: false,
        OriginalFileName:false,
        Notes:false,
        Message:false,
        Pages:false,
        TextSize:false,
        PdfSize:false,
        VirusStatus:false,
        CurrentStatus:false,
        FilePath:false
    });
    const [formData, setFormData] = useState(initialData);
    const [editType, setEditType] = useState('db');
    const [dialogOpen, setDialogOpen] = useState(false);

    const handleEditTypeChange = (event) => {
        setEditType(event.target.value);
    };

    const handleDBEditSubmit = async (event) => {
        event.preventDefault();
        setSnackbarOpen(true); // Open success snackbar
        const isFormDirty = Object.values(isFieldChanged).some(Boolean);
        console.log(isFormDirty)
        if(isFormDirty){
            // Handle DB edit submission logic here
            //console.log("Editing in DB...", formData);
            var response = await axios.put(`${API_URL}/api/adminsearch/edit/${systemgeneratedfilename}`, {formData, initialData, isFieldChanged})
            console.log(response)
        }
    };
    // Fetch documents when page or rowsPerPage changes
    useEffect(() => {
        if(rowsPerPage){
            fetchDocuments(page, rowsPerPage);
        }
    }, [page, rowsPerPage]);
    const fetchDocuments = async  (page, rowsPerPage) => {
        try {
            const from = page * rowsPerPage;
        const response = await axios.get(`${API_URL}/api/adminsearchES/view/${systemgeneratedfilename}/${from}/${rowsPerPage}`);
        if (response.data.success) {
            setDocuments(response.data.documents);
            setTotalResults(response.data.totalResults);
        } else {
            setError(response.data.message);
        }
        } catch (err) {
        setError('Error fetching data from Elasticsearch');
        } finally {
        setLoading(false);
        }
    };
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
      };
    
      const handleChangeRowsPerPage = (event) => {
        console.log(event.target.value)
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
      };
    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value,
        }));
    };
    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
      };
    useEffect(() => {
        const fetchFileDetails = async () => {
            try {
              const response = await axios.get(`${API_URL}/api/adminsearch/view/${systemgeneratedfilename}`);
              if(response.data.success){
                if(response.data.success){
                    const fetchedData = {
                        Title: response.data.result.Title || '',
                        Author: response.data.result.Author || '',
                        Year: response.data.result.Year || '',
                        SystemGeneratedFileName: response.data.result.SystemGeneratedFileName || '',
                        OriginalFileName:response.data.result.OriginalFileName || '',
                        Notes: response.data.result.Notes || '',
                        Message: response.data.result.Message || '',
                        Pages: response.data.result.Pages || '',
                        TextSize: response.data.result.TextSize || '',
                        PdfSize: response.data.result.PdfSize || '',
                        VirusStatus: response.data.result.VirusStatus || '',
                        CurrentStatus: response.data.result.CurrentStatus || '',
                        FilePath: response.data.result.FilePath || '',
                        FileId: response.data.result.FileId || '',
                    };
                    setInitialData(fetchedData);
                    setFormData(fetchedData);
                }
                
                
              }
            } catch (err) {
              console.error('Failed to fetch users', err);
            }
          };
          fetchFileDetails();
    }, [systemgeneratedfilename])
    useEffect(() => {
        // Update isFieldChanged state when formData changes
        const changes = {};
        Object.keys(formData).forEach(key => {
            changes[key] = formData[key] !== initialData[key];
        });
        setIsFieldChanged(changes);
    }, [formData, initialData]);
    console.log(isFieldChanged)
    const handleBeforeUnload = (event) => {
        const isFormDirty = Object.values(isFieldChanged).some(Boolean);
        if (isFormDirty) {
            event.preventDefault();
            event.returnValue = 'You have unsaved changes. Do you want to discard them?'; // Required for some browsers to show the confirmation dialog
        }
    };
    useEffect(() => {
        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [isFieldChanged]);
    const handleDiscard = () => {
        // Handle form discard
        setFormData(initialData);
        setDialogOpen(false); // Close the custom dialog
      };
    
      const handleNavigateAway = () => {
        const isFormDirty = Object.values(isFieldChanged).some(Boolean);
        if (isFormDirty) {
          setDialogOpen(true);
        } else {
          // Proceed with navigation or closing
        }
      };
      const handleEdit = (doc) => {
        // Handle edit functionality
        console.log("Editing document", doc);
      };
      
      const handleView = (systemgeneratedfilename, pagenumber, FileId) => {
        // Navigate to the view document page with the systemgeneratedfilename and pagenumber
        navigate(`/adminsearch/edit/view/${systemgeneratedfilename}/${pagenumber}/${FileId}`);
      };
      // Function to limit the content to 15 words
        const limitContent = (content) => {
            return content.split(' ').slice(0, 15).join(' ') + (content.split(' ').length > 15 ? '...' : '');
        };
  return (
    <Paper className="edit-page">
        <center><h3>Edit in: {systemgeneratedfilename}</h3></center>
        <FormControl component="fieldset" className="form-control">
            <RadioGroup
                row
                value={editType}
                onChange={handleEditTypeChange}
                className="radio-group"
            >
                <FormControlLabel value="db" control={<Radio />} label="Edit File Details" />
                <FormControlLabel value="es" control={<Radio />} label="Edit File content" />
            </RadioGroup>
        </FormControl>

        {editType === 'db' && (
            <>
            <form onSubmit={handleDBEditSubmit} className="form">
                <TextField
                    label="Title"
                    name="Title"
                    margin="normal"
                    className="text-field"
                    value={formData.Title}
                    onChange={handleChange}
                />
                <TextField
                    label="Author"
                    name="Author"
                    margin="normal"
                    className="text-field"
                    value={formData.Author}
                    onChange={handleChange}
                />
                <TextField
                    label="Year"
                    name="Year"
                    fullWidth
                    margin="normal"
                    className="text-field"
                    value={formData.Year}
                    InputLabelProps={{ shrink: true }}
                    onChange={handleChange}
                />
                <TextField
                    label="Notes"
                    name="Notes"
                    fullWidth
                    margin="normal"
                    className="text-field"
                    value={formData.Notes}
                    onChange={handleChange}
                    InputLabelProps={{ shrink: true }}
                />
                <TextField
                    label="System Generated File Name"
                    name="SystemGeneratedFileName"
                    fullWidth
                    margin="normal"
                    className="text-field"
                    value={formData.SystemGeneratedFileName}
                    InputLabelProps={{ shrink: true }}
                    disabled
                />
                <TextField
                    label="Original File Name"
                    name="Orig"
                    fullWidth
                    margin="normal"
                    className="text-field"
                    value={formData.OriginalFileName}
                    InputLabelProps={{ shrink: true }}
                    disabled
                />
                
                <TextField
                    label="Message"
                    name="Message"
                    fullWidth
                    margin="normal"
                    className="text-field"
                    value={formData.Message}
                    InputLabelProps={{ shrink: true }}
                    disabled
                />
                <TextField
                    label="Pages"
                    name="Pages"
                    fullWidth
                    margin="normal"
                    className="text-field"
                    disabled
                    InputLabelProps={{ shrink: true }}
                    value={formData.Pages}
                />
                <TextField
                    label="TextSize"
                    name="TextSize"
                    fullWidth
                    margin="normal"
                    className="text-field"
                    disabled
                    InputLabelProps={{ shrink: true }}
                    value={formData.TextSize}
                />
                <TextField
                    label="PDF Size"
                    name="PdfSize"
                    fullWidth
                    margin="normal"
                    className="text-field"
                    disabled
                    InputLabelProps={{ shrink: true }}
                    value={formData.PdfSize}
                />
                <TextField
                    label="Virus Status"
                    name="VirusStatus"
                    fullWidth
                    margin="normal"
                    className="text-field"
                    disabled
                    InputLabelProps={{ shrink: true }}
                    value={formData.VirusStatus}
                />
                <TextField
                    label="Current Status"
                    name="CurrentStatus"
                    fullWidth
                    margin="normal"
                    className="text-field"
                    disabled
                    InputLabelProps={{ shrink: true }}
                    value={formData.CurrentStatus}
                />
                <TextField
                    label="File path"
                    name="FilePath"
                    fullWidth
                    margin="normal"
                    className="text-field"
                    value={formData.FilePath}
                    InputLabelProps={{ shrink: true }}
                    disabled
                />
                <TextField
                    label="File id"
                    name="FileId"
                    fullWidth
                    margin="normal"
                    className="text-field"
                    value={formData.FileId}
                    InputLabelProps={{ shrink: true }}
                    hidden
                />
                <div className="button-group">
                    <Button type="submit" variant="contained" color="primary" className="button">
                        Save in DB
                    </Button>
                    <Button type="button" onClick={() => handleNavigateAway()}>Discard</Button>
                </div>
            </form>
            <CustomDialog
                open={dialogOpen}
                onClose={() => setDialogOpen(false)}
                onConfirm={handleDiscard}
            />
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                message="Successfully saved"
                action={
                <IconButton
                    size="big"
                    aria-label="close"
                    color="green"
                    onClick={handleCloseSnackbar}
                >
                    <CloseIcon fontSize="small" />
                </IconButton>
                }
            />
          </>
        )}

        {editType === 'es' && (
            <Paper>
                <TableContainer>
                <Table>
                    <TableHead>
                    <TableRow>
                        <TableCell>Sr. No.</TableCell>
                        <TableCell>Content</TableCell>
                        <TableCell>Page Number</TableCell>
                        <TableCell>View</TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                    {documents.map((doc, index) => (
                        <TableRow key={index}>
                        <TableCell>{index+1}</TableCell>
                        <TableCell>
                            {limitContent(doc.content)} {/* Limit content to 15 words */}
                        </TableCell>
                        <TableCell>{doc.pagenumber.replace(/^0+/, '')}</TableCell>
                        <TableCell>
                        <Button
                                variant="contained"
                                color="primary"
                                onClick={() => handleView(doc.systemgeneratedfilename, doc.pagenumber, formData.FileId)}
                            >View</Button>
                        </TableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
                </TableContainer>
                <TablePagination
                component="div"
                count={totalResults}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
            )}
        </Paper>
  )
}
