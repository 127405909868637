import React from "react";
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AdminSearchEdit } from "./components/AdminSearch/AdminSearchEdit/AdminSearchEdit";
import { MainPage } from "./components/mainpage/MainPage";
import { Logs } from "./components/Logs/Logs";
import { ViewLog } from "./components/Logs/Viewlog";
import ViewDocument from "./components/AdminSearch/ViewDocument/ViewDocument";
import EditDocument from "./components/AdminSearch/EditDocument/EditDocument";
// import { RtoE } from "./components/rtoE/RtoE";
// import { RtoN } from "./components/RtoN/RtoN";
// import { RtoNtoDbtoEs } from "./components/RtoNtoDBtoES/RtoNtoDbtoEs";
// import { RtoNtoDb } from "./components/RtoNtoDb/RtoNtoDb";
function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<MainPage />} />
        <Route path="/adminsearch/edit/:systemgeneratedfilename" element={<AdminSearchEdit />} />
        <Route path="/adminsearch/edit/view/:systemgeneratedfilename/:pagenumber/:FileId" element={<ViewDocument />} />
        <Route path="/adminsearch/edit/edit/:systemgeneratedfilename/:pagenumber/:FileId" element={<EditDocument />} />
        <Route path="/logs" element={<Logs />} />
        <Route path="/log/:logid" element={<ViewLog />} />
      </Routes>
    </Router>
  );
}

export default App;
